import React from 'react';
import Layout from '../../components/Layout';
import ebook from '../../img/design/ebook.png';
import Contact from '../../components/Contact';

const ThankYou = () => (
    <Layout>
        <section className="section section--salmon section--with-mask mask-four">
            <div className="hero-body ">
                <div className="container">
                    <div className="columns is-centered is-vcentered">
                        <div className="column has-text-centered">
                            <h2 className="sitteroo-h2 has-text-left">
                            sign up for our newsletter
                                <p className="sitteroo-monday-picnic brown has-text-right">
                                and get the free ebook!
                                </p>
                            </h2>
                            <div
                                className="container"
                                style={{ maxWidth: '400px' }}
                            >
                                <Contact />
                            </div>
                            <div
                                className="content has-text-justified pt-5 has-text-weight-semibold">
                                <p>
                                    I&apos;m Magda, and I&apos;m delighted to invite you and your kids to join me for a fun-filled playtime adventure.
                                    With several years of experience as a Childcare Educator, I&apos;m excited to share my expertise and knowledge with you.
                                    I&apos;ve created an ebook that features the very best activities, I&apos;ve had the pleasure of participating in.
                                </p>
                                <p className="brown">
                                    This ebook is perfect for you if:
                                </p>
                                <ul className="">
                                    <li>You want to spend quality time with your child in a creative way.</li>
                                    <li>You are a caregiver looking for fresh and exciting playtime ideas for your little one.</li>
                                    <li>You are seeking inspiration for leisure activities with your young child. </li>
                                    <li>You wish to support their development through engaging play.</li>
                                    <li>You deeply care about nurturing and strengthening your special bond with your little one.</li>
                                </ul>
                                <p className="">
                                    Join me on this remarkable adventure of helping your child develop new skills,
                                    fostering a deep connection through play, and creating unforgettable memories together!
                                </p>
                            </div>
                        </div>
                        <div className="column has-text-centered">
                            <img src={ebook} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default ThankYou;
